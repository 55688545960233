export default function handleAnchor(document, anchor) {
    var _a;
    if (!document.getElementById(anchor)) {
        console.error(`Anchor ${anchor} not found`);
        return;
    }
    window.scrollTo({
        top: (_a = document.getElementById(anchor)) === null || _a === void 0 ? void 0 : _a.offsetTop,
        behavior: 'smooth',
    });
}
export const availableAnchors = {
    TOP: 'id-section-top',
    SERVICES: 'id-section-services',
    PARTNERS: 'id-section-partners',
    CONTACT: 'id-section-contact',
    ABOUT: 'id-section-about',
};
