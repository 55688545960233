<script>
  import Header from './components/header.svelte'
  import Footer from './components/footer.svelte'
  import Services from './components/services.svelte'
  import Partners from './components/partners.svelte'
  import About from './components/about.svelte'
</script>

<main>
  <Header />
  <About />
  <Services />
  <Partners />
  <Footer />
</main>

<style>
  main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
  }
</style>
