<script>
  export let height = '2em'
  export let fill = '#FFF'
  export let opacity = '1'
  function openInstagram() {
    window.open('https://dustdune.com')
  }
</script>

<button on:click={openInstagram}>
  <svg
    {height}
    viewBox="0 0 1947 274"
    xmlns="http://www.w3.org/2000/svg"
    {opacity}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1946.4 0.234985L1946.45 204.328C1897.52 184.044 1833.44 134.208 1789.58 100.101L1787.1 98.1753C1785.52 96.9493 1783.97 95.7454 1782.45 94.566C1778.23 90.9825 1770.04 93.1659 1761.6 95.4182C1752.97 97.7196 1744.07 100.093 1738.85 96.4585C1735.16 94.6543 1726.76 88.8376 1717.06 82.1271C1708.16 75.9669 1698.17 69.0537 1689.75 63.8002C1667.94 50.9081 1658.54 56.7729 1641.92 67.1473C1639.93 68.3901 1637.84 69.6978 1635.61 71.0456C1591.8 102.082 1547.94 132.902 1512.93 132.902C1502.59 132.101 1491.93 125.457 1481.62 119.032C1467.59 110.281 1454.2 101.937 1443.17 109.327C1383.74 143.337 1299.32 187.242 1229.76 205.68L1229.71 0.234985H1946.4Z"
      {fill}
    />
    <path
      d="M1946.45 208.076L1946.4 273.208H1229.67L1229.71 209.104C1245.84 209.104 1264.47 209.104 1290.67 203.48C1329.85 194.722 1367.77 175.739 1404.65 157.273C1413.99 152.596 1423.27 147.953 1432.48 143.517C1454.48 134.19 1465.99 141.755 1481.85 152.171L1481.85 152.173C1487.41 155.826 1493.5 159.829 1500.77 163.577C1541.07 185.259 1591.69 208.833 1632.04 210.672C1616.55 205.974 1594.51 191.367 1579.96 181.721C1577.93 180.375 1576.04 179.126 1574.34 178.013C1546.1 159.792 1551.4 154.439 1574.34 141.895C1577.43 140.208 1580.92 138.189 1584.47 136.134L1584.5 136.116L1584.54 136.093L1584.55 136.086L1584.56 136.077L1584.57 136.072C1588.84 133.601 1593.19 131.082 1597.04 129.026C1598.4 128.091 1599.73 127.181 1601.02 126.293C1601.96 125.645 1602.89 125.009 1603.79 124.384C1615.9 116.057 1625.11 109.718 1635.61 103.613C1660.03 87.284 1661.81 87.284 1686.23 100.153C1705.65 110.843 1724.49 123.292 1743.2 135.661C1771.81 154.572 1800.14 173.295 1829.83 185.259C1826.29 181.632 1820.55 177.429 1814.1 172.708L1814.1 172.705C1794.9 158.637 1769.43 139.979 1777.16 118.32C1778.94 112.697 1793.04 121.781 1793.04 121.781C1802.28 127.232 1813.06 135.211 1825.18 144.189C1857.31 167.978 1898.91 198.773 1946.45 208.076Z"
      {fill}
    />
    <path
      d="M0.969727 271.04L0.969727 140.691H60.1609C74.3334 140.691 86.7552 143.36 97.5931 148.781C108.431 154.202 116.851 161.791 122.937 171.465C129.023 181.222 132.024 192.647 132.024 205.824C132.024 219.001 129.023 230.426 122.937 240.183C116.851 249.941 108.431 257.53 97.5931 262.867C86.7552 268.288 74.3334 270.957 60.1609 270.957H0.969727V271.04ZM31.3156 246.438H58.6603C67.3305 246.438 74.917 244.77 81.253 241.518C87.6723 238.265 92.591 233.512 96.1758 227.34C99.6772 221.169 101.511 213.997 101.511 205.824C101.511 197.484 99.7606 190.312 96.1758 184.224C92.6743 178.136 87.6723 173.466 81.253 170.13C74.8336 166.878 67.3305 165.21 58.6603 165.21H31.3156L31.3156 246.438Z"
      {fill}
    />
    <path
      d="M217.392 273.208C198.884 273.208 184.461 268.038 174.04 257.78C163.619 247.522 158.367 232.678 158.367 213.496V140.691H188.713V212.746C188.713 225.005 191.214 233.929 196.383 239.433C201.468 244.937 208.555 247.689 217.642 247.689C226.812 247.689 233.982 244.937 239.067 239.433C244.153 233.929 246.737 225.005 246.737 212.746V140.691L276.333 140.691V213.496C276.333 232.761 271.164 247.522 260.66 257.78C250.155 268.121 235.733 273.208 217.392 273.208Z"
      {fill}
    />
    <path
      d="M353.866 273.202C343.445 273.202 333.441 271.785 323.77 269.032C314.183 266.28 306.429 262.611 300.594 258.108L310.848 235.424C316.267 239.427 322.853 242.679 330.606 245.181C338.276 247.766 346.029 249.017 353.866 249.017C359.785 249.017 364.62 248.434 368.288 247.183C371.957 245.932 374.624 244.264 376.375 242.262C378.126 240.177 378.959 237.842 378.959 235.09C378.959 231.588 377.626 228.835 374.874 226.834C372.123 224.832 368.539 223.081 363.953 221.83C359.451 220.496 354.449 219.245 349.114 218.077C343.778 216.91 338.443 215.492 333.024 213.824C327.605 212.156 322.686 209.904 318.101 207.152C313.599 204.317 310.014 200.731 307.263 196.144C304.595 191.641 303.262 185.97 303.262 179.131C303.262 171.709 305.262 164.87 309.181 158.699C313.182 152.528 319.185 147.607 327.355 144.021C335.441 140.352 345.696 138.517 357.951 138.517C366.121 138.517 374.207 139.518 382.211 141.519C390.214 143.521 397.217 146.356 403.136 150.109L393.632 172.793C387.796 169.457 381.794 166.955 375.541 165.287C369.372 163.619 363.37 162.785 357.701 162.785C351.782 162.785 346.946 163.453 343.361 164.704C339.777 166.038 337.109 167.789 335.441 170.041C333.774 172.293 332.94 174.795 332.94 177.463C332.94 180.966 334.274 183.718 337.025 185.803C339.777 187.888 343.361 189.556 347.863 190.723C352.365 191.891 357.284 193.058 362.786 194.226C368.205 195.393 373.624 196.895 378.959 198.646C384.295 200.397 389.214 202.566 393.632 205.234C398.134 207.903 401.719 211.489 404.553 216.076C407.388 220.579 408.722 226.25 408.722 232.922C408.722 240.261 406.721 246.932 402.636 253.02C398.634 259.108 392.465 264.029 384.295 267.698C376.375 271.368 366.121 273.202 353.866 273.202Z"
      {fill}
    />
    <path
      d="M459.409 271.033V165.203H417.725V140.601L531.272 140.601V165.203L489.588 165.203V271.033H459.409Z"
      {fill}
    />
    <path
      d="M731.439 171.458C725.353 161.701 716.85 154.028 706.095 148.774C697.092 144.271 687.087 141.602 675.749 140.852C669.247 141.686 663.328 144.688 658.826 149.191C654.657 153.361 651.656 159.032 650.572 165.203H667.079C675.833 165.203 683.336 166.871 689.672 170.124C695.841 173.293 700.676 177.629 704.095 183.384C704.178 183.384 704.178 183.467 704.261 183.551C704.345 183.717 704.511 183.968 704.595 184.134C708.096 190.222 709.93 197.394 709.93 205.734C709.93 213.99 708.096 221.162 704.595 227.25C704.345 227.751 704.011 228.168 703.678 228.668C700.343 234.172 695.591 238.342 689.672 241.344C683.336 244.68 675.75 246.348 667.079 246.348H639.735V140.518H638.067C629.73 140.852 622.227 144.438 616.642 149.942C610.723 155.863 609.389 164.119 609.389 173.126V270.95H668.58C682.752 270.95 695.174 268.198 705.929 262.777C716.766 257.356 725.187 249.851 731.273 240.093C737.358 230.419 740.36 218.911 740.36 205.734C740.526 192.641 737.525 181.132 731.439 171.458Z"
      {fill}
    />
    <path
      d="M825.811 273.208C807.304 273.208 792.881 268.038 782.46 257.78C772.039 247.522 766.787 232.678 766.787 213.496V140.691H797.133V212.746C797.133 225.005 799.717 233.929 804.803 239.433C809.888 244.937 816.975 247.689 826.062 247.689C835.232 247.689 842.402 244.937 847.487 239.433C852.573 233.929 855.157 225.005 855.157 212.746V140.691L884.753 140.691V213.496C884.753 232.761 879.5 247.522 869.079 257.78C858.575 268.121 844.152 273.208 825.811 273.208Z"
      {fill}
    />
    <path
      d="M1038.81 140.601V271.033H1013.89L949.11 191.89V271.033H919.265V140.601H944.275L1009.05 219.745V140.601L1038.81 140.601Z"
      {fill}
    />
    <path
      d="M1175.29 246.765V271.033H1074.41V140.601L1172.87 140.601V164.786H1104.34V192.974H1164.87V216.575H1104.34V246.765H1175.29Z"
      {fill}
    />
    <path
      d="M19.2847 71.7047H0.969727L24.7585 1.19893H47.4457L71.2345 71.7047H52.9195L36.3603 18.9631H35.8095L19.2847 71.7047ZM16.8404 43.9568H55.1228V56.9013H16.8404V43.9568Z"
      {fill}
    />
    <path
      d="M116.557 71.7047L96.0389 1.19893H114.87L125.508 47.3995H126.093L138.246 1.19893H153.6L165.753 47.5028H166.338L177.01 1.19893H195.807L175.324 71.7047H158.902L146.199 28.9468H145.648L132.944 71.7047H116.557Z"
      {fill}
    />
    <path
      d="M202.848 71.7047V1.19893H252.009V15.0384H219.889V29.4976H249.496V43.3716H219.889V57.8652H252.009V71.7047H202.848Z"
      {fill}
    />
    <path
      d="M262.681 71.7047V1.19893H292.081C297.36 1.19893 301.778 1.94484 305.336 3.43666C308.916 4.92848 311.601 7.01703 313.392 9.70231C315.205 12.3876 316.111 15.4975 316.111 19.0319C316.111 21.7172 315.549 24.1156 314.424 26.2271C313.3 28.3157 311.751 30.0485 309.777 31.4255C307.803 32.8026 305.519 33.7665 302.926 34.3174V35.0059C305.772 35.1436 308.4 35.9125 310.81 37.3125C313.242 38.7125 315.193 40.6634 316.662 43.165C318.131 45.6437 318.865 48.5815 318.865 51.9782C318.865 55.7652 317.901 59.1505 315.974 62.1341C314.046 65.0948 311.257 67.4358 307.608 69.1571C303.959 70.8555 299.529 71.7047 294.319 71.7047H262.681ZM279.722 57.9685H290.257C293.952 57.9685 296.672 57.2685 298.416 55.8685C300.183 54.4684 301.067 52.5176 301.067 50.0159C301.067 48.2028 300.642 46.6421 299.793 45.3339C298.944 44.0027 297.739 42.9814 296.178 42.2699C294.618 41.5355 292.747 41.1683 290.567 41.1683H279.722V57.9685ZM279.722 30.1862H289.155C291.014 30.1862 292.667 29.8763 294.113 29.2567C295.559 28.637 296.683 27.7419 297.486 26.5714C298.313 25.4009 298.726 23.9894 298.726 22.3369C298.726 19.9729 297.888 18.1139 296.213 16.7598C294.537 15.4057 292.277 14.7286 289.431 14.7286H279.722V30.1862Z"
      {fill}
    />
    <path
      d="M365.169 22.3369C364.94 19.8123 363.918 17.85 362.105 16.4499C360.315 15.027 357.756 14.3155 354.428 14.3155C352.225 14.3155 350.389 14.6024 348.92 15.1762C347.451 15.7499 346.349 16.5417 345.615 17.5516C344.88 18.5385 344.502 19.6746 344.479 20.9598C344.433 22.0156 344.64 22.9451 345.099 23.7484C345.581 24.5517 346.269 25.2632 347.164 25.8828C348.082 26.4796 349.184 27.0074 350.469 27.4665C351.754 27.9255 353.2 28.3271 354.807 28.6714L360.866 30.0485C364.355 30.8059 367.43 31.8157 370.092 33.078C372.778 34.3403 375.027 35.8436 376.84 37.5879C378.676 39.3322 380.065 41.3404 381.006 43.6126C381.947 45.8847 382.428 48.4323 382.451 51.2553C382.428 55.7078 381.304 59.5292 379.078 62.7194C376.851 65.9096 373.65 68.3539 369.473 70.0522C365.318 71.7506 360.304 72.5998 354.428 72.5998C348.53 72.5998 343.389 71.7162 339.005 69.949C334.621 68.1817 331.213 65.4964 328.78 61.8931C326.347 58.2898 325.097 53.734 325.028 48.2257H341.346C341.484 50.4979 342.092 52.3914 343.171 53.9061C344.249 55.4209 345.73 56.5685 347.612 57.3488C349.517 58.1291 351.72 58.5193 354.222 58.5193C356.517 58.5193 358.468 58.2095 360.074 57.5898C361.704 56.9701 362.954 56.1094 363.827 55.0078C364.699 53.9061 365.146 52.6438 365.169 51.2209C365.146 49.8897 364.733 48.7536 363.93 47.8126C363.127 46.8487 361.887 46.0224 360.212 45.3339C358.559 44.6224 356.448 43.9683 353.877 43.3716L346.51 41.6502C340.405 40.2502 335.597 37.9895 332.085 34.8682C328.574 31.7239 326.829 27.4779 326.852 22.1303C326.829 17.7696 328 13.9483 330.364 10.6663C332.728 7.38425 335.998 4.82521 340.176 2.98912C344.353 1.15303 349.115 0.234985 354.463 0.234985C359.925 0.234985 364.664 1.1645 368.681 3.02354C372.72 4.85963 375.853 7.44163 378.079 10.7695C380.306 14.0975 381.442 17.9532 381.488 22.3369H365.169Z"
      {fill}
    />
    <path d="M408.099 1.19893V71.7047H391.058V1.19893H408.099Z" {fill} />
    <path
      d="M416.491 15.0384V1.19893H476.083V15.0384H454.704V71.7047H437.904V15.0384H416.491Z"
      {fill}
    />
    <path
      d="M484.406 71.7047V1.19893H533.567V15.0384H501.447V29.4976H531.054V43.3716H501.447V57.8652H533.567V71.7047H484.406Z"
      {fill}
    />
    <path
      d="M629.067 26.7435H611.853C611.624 24.9763 611.153 23.3812 610.442 21.9582C609.73 20.5352 608.789 19.3188 607.619 18.309C606.448 17.2991 605.06 16.5303 603.453 16.0024C601.87 15.4516 600.114 15.1762 598.186 15.1762C594.766 15.1762 591.817 16.0139 589.338 17.6893C586.883 19.3647 584.989 21.7861 583.658 24.9533C582.35 28.1206 581.696 31.9534 581.696 36.4518C581.696 41.1338 582.361 45.0585 583.692 48.2257C585.047 51.37 586.94 53.7455 589.373 55.3521C591.829 56.9357 594.732 57.7275 598.083 57.7275C599.965 57.7275 601.675 57.4865 603.212 57.0045C604.773 56.5226 606.139 55.8226 607.309 54.9045C608.503 53.9635 609.478 52.8274 610.235 51.4963C611.016 50.1422 611.555 48.6159 611.853 46.9175L629.067 47.0208C628.768 50.1422 627.862 53.2176 626.347 56.2472C624.855 59.2767 622.801 62.0423 620.185 64.544C617.568 67.0227 614.378 68.9965 610.614 70.4654C606.873 71.9342 602.581 72.6687 597.739 72.6687C591.358 72.6687 585.643 71.2686 580.594 68.4686C575.568 65.6456 571.597 61.5374 568.682 56.1439C565.768 50.7504 564.31 44.1863 564.31 36.4518C564.31 28.6944 565.791 22.1189 568.751 16.7254C571.712 11.3318 575.717 7.23507 580.766 4.43504C585.815 1.635 591.473 0.234985 597.739 0.234985C602.007 0.234985 605.955 0.831715 609.581 2.02517C613.208 3.19568 616.398 4.91701 619.152 7.18917C621.906 9.43838 624.144 12.204 625.865 15.486C627.586 18.768 628.654 22.5205 629.067 26.7435Z"
      {fill}
    />
    <path
      d="M638.818 71.7047V1.19893H667.943C673.222 1.19893 677.778 2.1514 681.61 4.05634C685.466 5.93833 688.438 8.64656 690.527 12.181C692.616 15.6926 693.66 19.8582 693.66 24.6779C693.66 29.5665 692.593 33.7206 690.458 37.1404C688.324 40.5371 685.294 43.1306 681.369 44.9208C677.445 46.688 672.797 47.5716 667.427 47.5716H649.008V34.1452H664.259C666.83 34.1452 668.976 33.8124 670.697 33.1469C672.441 32.4583 673.761 31.4255 674.656 30.0485C675.551 28.6485 675.999 26.8583 675.999 24.6779C675.999 22.4976 675.551 20.6959 674.656 19.2729C673.761 17.827 672.441 16.7483 670.697 16.0368C668.953 15.3024 666.807 14.9352 664.259 14.9352H655.859V71.7047H638.818ZM678.512 39.4814L696.07 71.7047H677.479L660.266 39.4814H678.512Z"
      {fill}
    />
    <path
      d="M703.007 71.7047V1.19893H752.168V15.0384H720.048V29.4976H749.655V43.3716H720.048V57.8652H752.168V71.7047H703.007Z"
      {fill}
    />
    <path
      d="M778.091 71.7047H759.776L783.565 1.19893H806.252L830.041 71.7047H811.726L795.167 18.9631H794.616L778.091 71.7047ZM775.647 43.9568H813.929V56.9013H775.647V43.9568Z"
      {fill}
    />
    <path
      d="M826.521 15.0384V1.19893H886.113V15.0384H864.734V71.7047H847.934V15.0384H826.521Z"
      {fill}
    />
    <path
      d="M894.436 71.7047V1.19893H943.597V15.0384H911.477V29.4976H941.084V43.3716H911.477V57.8652H943.597V71.7047H894.436Z"
      {fill}
    />
    <path
      d="M980.331 71.7047H954.27V1.19893H980.296C987.48 1.19893 993.665 2.61043 998.852 5.43341C1004.06 8.23344 1008.08 12.2728 1010.9 17.5516C1013.72 22.8074 1015.14 29.096 1015.14 36.4174C1015.14 43.7617 1013.72 50.0733 1010.9 55.3521C1008.1 60.6308 1004.1 64.6817 998.886 67.5047C993.677 70.3047 987.491 71.7047 980.331 71.7047ZM971.311 57.1767H979.676C983.624 57.1767 986.963 56.5111 989.695 55.1799C992.449 53.8258 994.526 51.634 995.926 48.6044C997.349 45.5519 998.06 41.4896 998.06 36.4174C998.06 31.3452 997.349 27.3058 995.926 24.2992C994.503 21.2697 992.403 19.0893 989.626 17.7582C986.872 16.404 983.475 15.727 979.435 15.727H971.311V57.1767Z"
      {fill}
    />
    <path
      d="M1045.91 71.7047V1.19893H1075.31C1080.59 1.19893 1085.01 1.94484 1088.57 3.43666C1092.15 4.92848 1094.83 7.01703 1096.62 9.70231C1098.44 12.3876 1099.34 15.4975 1099.34 19.0319C1099.34 21.7172 1098.78 24.1156 1097.66 26.2271C1096.53 28.3157 1094.98 30.0485 1093.01 31.4255C1091.04 32.8026 1088.75 33.7665 1086.16 34.3174V35.0059C1089 35.1436 1091.63 35.9125 1094.04 37.3125C1096.47 38.7125 1098.43 40.6634 1099.89 43.165C1101.36 45.6437 1102.1 48.5815 1102.1 51.9782C1102.1 55.7652 1101.13 59.1505 1099.21 62.1341C1097.28 65.0948 1094.49 67.4358 1090.84 69.1571C1087.19 70.8555 1082.76 71.7047 1077.55 71.7047H1045.91ZM1062.95 57.9685H1073.49C1077.18 57.9685 1079.9 57.2685 1081.65 55.8685C1083.42 54.4684 1084.3 52.5176 1084.3 50.0159C1084.3 48.2028 1083.87 46.6421 1083.03 45.3339C1082.18 44.0027 1080.97 42.9814 1079.41 42.2699C1077.85 41.5355 1075.98 41.1683 1073.8 41.1683H1062.95V57.9685ZM1062.95 30.1862H1072.39C1074.25 30.1862 1075.9 29.8763 1077.34 29.2567C1078.79 28.637 1079.92 27.7419 1080.72 26.5714C1081.54 25.4009 1081.96 23.9894 1081.96 22.3369C1081.96 19.9729 1081.12 18.1139 1079.44 16.7598C1077.77 15.4057 1075.51 14.7286 1072.66 14.7286H1062.95V30.1862Z"
      {fill}
    />
    <path
      d="M1106.78 1.19893H1125.82L1140.72 30.7026H1141.34L1156.25 1.19893H1175.29L1149.5 48.1569V71.7047H1132.57V48.1569L1106.78 1.19893Z"
      {fill}
    />
  </svg>
</button>

<style>
  svg:hover {
    opacity: 1;
    transition: opacity 0.3s;
  }
</style>
