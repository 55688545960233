<script>
  export let height = '2em'
  export let width = '2em'
  export let fill = '#fff'
  export let text = ''
  function openInstagram() {
    window.open('')
  }
</script>

<button on:click={openInstagram}>
  <svg
    {height}
    {width}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.44 16.0818L16.808 16.748C16.808 16.748 15.3039 18.3303 11.1997 14.0097C7.09559 9.68909 8.59974 8.10673 8.59974 8.10673L8.99696 7.68614C9.9789 6.65377 10.072 4.99494 9.21502 3.78316L7.46503 1.30814C6.40392 -0.191869 4.35533 -0.3904 3.14006 0.889022L0.959516 3.18316C0.358131 3.81846 -0.0446441 4.63905 0.00396664 5.55082C0.128966 7.88467 1.12618 12.9038 6.68726 18.7598C12.5858 24.9686 18.1205 25.2157 20.383 24.9922C21.0997 24.9216 21.7219 24.5363 22.2233 24.0069L24.1955 21.9304C25.5288 20.5289 25.1538 18.1245 23.4483 17.1436L20.7955 15.6156C19.6761 14.973 18.315 15.1612 17.44 16.0818Z"
      {fill}
    />
  </svg>
  {#if text.length > 0}
    <p style="color: {fill}">
      {text}
    </p>
  {/if}
</button>

<style>
  button {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  p {
    font-family: 'popins';
    margin-left: 0.6em;
  }
</style>
