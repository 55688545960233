<script>
  import SectionHeader from './sectionHeader.svelte'

  export let anchor = null
  export let bgColor = '#ffffff'
  export let title = null
</script>

<section id={anchor} class="container" style="background-color: {bgColor}">
  {#if title}
    <SectionHeader {title} />
  {/if}
  <slot></slot>
</section>

<style>
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4em 0;
  }
</style>
