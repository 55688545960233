<script>
  import InstagramLogo from '../icons/Instagram.svelte'
  import MailLogo from '../icons/Mail.svelte'
  import LogoWhite from '../icons/logo_white.svelte'
  import DDHorizontal from '../icons/dd_horizontal.svelte'
  import Phone from '../icons/phone.svelte'
  import handleAnchor, { availableAnchors } from '../utils/handleAnchor'
</script>

<footer>
  <div class="content-container">
    <div class="left-column">
      <LogoWhite height="40" fill="#ffffff" />
      <p class="sinopsis">
        Consultoria estratégica e de atendimento legal para a sua empresa.
      </p>
      <div class="media">
        <InstagramLogo
          width="1.5em"
          text="https://www.instagram.com/gruposalubre"
        />
        <MailLogo text="www.gruposalubre.com.br" width="1.5em" />
        <Phone text="+55 (81) 99593-2401" width="1.3em" />
      </div>
    </div>
    <div class="right-column">
      <button
        on:click={() => {
          handleAnchor(document, availableAnchors.TOP)
        }}>Início</button
      >
      <button
        on:click={() => {
          handleAnchor(document, availableAnchors.ABOUT)
        }}>Sobre nós</button
      >
      <button
        on:click={() => {
          handleAnchor(document, availableAnchors.SERVICES)
        }}>Nossos Serviços</button
      >
      <button
        on:click={() => {
          handleAnchor(document, availableAnchors.PARTNERS)
        }}>Nossos Parceiros</button
      >
      <button
        on:click={() => {
          handleAnchor(document, availableAnchors.CONTACT)
        }}>Contato</button
      >
    </div>
  </div>
  <div class="footer-bottom" style="">
    <div class="content-container2">
      <p>© Copyright 2024 - Grupo Salubre, todos os direitos reservados</p>
      <DDHorizontal height="30" fill="#fff" opacity="0.5" />
    </div>
  </div>
</footer>

<style>
  footer {
    display: flex;
    padding-top: 2em;
    width: 100%;
    min-height: 20em;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    background-color: #166d4a;
  }

  .footer-bottom {
    background-color: #1a4d37;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    padding: 1em 2em;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
    }
  }

  .content-container2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    padding: 1em 2em;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
    }
  }

  .content-container2 p {
    opacity: 0.5;
    @media (max-width: 900px) {
      text-align: center;
      margin-top: 1em;
    }
  }

  .left-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 900px) {
      align-items: center;
    }
  }

  .right-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1em;

    @media (max-width: 900px) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 3em;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      gap: 0.5em;
    }
  }

  .sinopsis {
    text-align: left;
    max-width: 20em;
    font-size: 1.2rem;
    opacity: 0.7;
    margin: 1em 0;

    @media (max-width: 900px) {
      text-align: center;
    }
  }

  .media {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.3em;
    opacity: 0.7;

    @media (max-width: 900px) {
      align-items: center;
    }
  }

  button {
    color: #ffffff;
    font-family: 'popins';
    font-size: 1.2rem;
    opacity: 0.7;
    border-right: 0.2em solid #ffffff;
    padding-right: 1em;

    @media (max-width: 900px) {
      display: flex;
      border-right: none;
      padding-right: 0em;
      padding: 0 0.5em;
    }

    @media (max-width: 600px) {
      font-size: 1rem;
    }

    @media (max-width: 500px) {
      display: flex;
      justify-content: center;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  button:hover {
    opacity: 1;
    transition: 0.3s;
  }
</style>
