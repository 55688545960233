<script>
  import SectionContainer from './sectionContainer.svelte'
  import { services } from '../constants/services'
  import Star from '../icons/Star.svelte'
  import { availableAnchors } from '../utils/handleAnchor.ts'
</script>

<SectionContainer
  anchor={availableAnchors.SERVICES}
  bgColor="#f5f5f5"
  title="Nossos serviços"
>
  <div class="container">
    {#each services as service}
      <div class="service-card">
        <div class="title-container">
          <Star height="30" fill={service.color} />
          <h3 style="color: {service.color}">{service.title}</h3>
        </div>
        <lu>
          {#each service.items as features}
            <li>{features}</li>
          {/each}
        </lu>
        <div class="price">
          <p>{service.price}</p>
          <p class="descriptor">/ ano</p>
        </div>
        <p class="extra-info">
          *planos semestrais e trimestrais disponíveis! Aceita parcelamento
          mensal.
        </p>
        <button
          on:click={() => {
            window.open(
              `https://api.whatsapp.com/send?phone=558195932401&text=${service.message}`
            )
          }}
        >
          <p>Contratar</p>
        </button>
      </div>
    {/each}
  </div>
</SectionContainer>

<style>
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'popins';
    font-size: 2rem;
    background-color: #f5f5f5;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  .service-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 10em;
    margin: 0.5em;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    @media (max-width: 800px) {
      width: 90%;
    }
  }

  .title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    font-size: 1.7rem;
    font-weight: 600;
    align-self: center;
    text-align: center;
    color: #555;
    padding: 0.5em;
    border-bottom: 2px solid #f5f5f5;

    @media (max-width: 1000px) {
      font-size: 1.5rem;
    }
  }

  button {
    height: 5em;
    margin: 1em;
    background-color: #166d4a;
    border: none;
    cursor: pointer;
    border-radius: 10px;
  }

  button p {
    color: #fff;
    font-family: 'popins';
    font-size: 1.5rem;
    font-weight: 400;
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0.5em;
    color: #3e7646;
  }

  .extra-info {
    font-size: 0.8rem;
    text-align: center;
    margin: 0.5em;
    color: #acacac;
  }

  .descriptor {
    font-size: 1rem;
    margin-left: 0.5em;
  }

  li {
    font-size: 0.9rem;
  }
  lu {
    padding: 1em;
  }
</style>
