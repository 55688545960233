<script>
  import { availableAnchors } from '../utils/handleAnchor'
  import SectionContainer from './sectionContainer.svelte'
</script>

<SectionContainer
  anchor={availableAnchors.ABOUT}
  bgColor="#fff"
  title="Sobre nós"
>
  <div class="container">
    <p>
      Organização com <strong>14 anos</strong> de atuação na área de
      <strong>SSMA</strong>, focada no desenvolvimento humano e na implantação
      da cultura de SSMA nas empresas.
    </p>
  </div>
</SectionContainer>

<style>
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'popins';
    font-size: 2rem;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
  p {
    margin: 1em;
    text-align: center;
  }

  strong {
    color: #166d4a;
  }
</style>
