<script>
  import { onMount, onDestroy } from 'svelte'
  import { partners } from '../constants/partners'
  import SectionContainer from './sectionContainer.svelte'
  import { availableAnchors } from '../utils/handleAnchor'
  let partnerGroups = []
  let partnerGroupsNumber = 7

  const calculateGroups = (windowSize) => {
    if (windowSize < 768) {
      partnerGroupsNumber = 4
    } else if (windowSize < 1024) {
      partnerGroupsNumber = 5
    } else {
      partnerGroupsNumber = 7
    }
    return partners.reduce((acc, partner, index) => {
      const groupIndex = Math.floor(index / partnerGroupsNumber)
      if (!acc[groupIndex]) {
        acc[groupIndex] = []
      }
      acc[groupIndex].push(partner)
      return acc
    }, [])
  }

  const handleResize = () => {
    partnerGroups = calculateGroups(window.innerWidth)
  }
  onMount(() => {
    partnerGroups = calculateGroups(window.innerWidth)
    window.addEventListener('resize', handleResize)
  })
  onDestroy(() => {
    window.removeEventListener('resize', handleResize)
  })
</script>

<SectionContainer
  anchor={availableAnchors.PARTNERS}
  bgColor="#ffffff"
  title="Parceiros"
>
  <div class="container">
    {#each partnerGroups as partnerGroup}
      <div class="partners-row">
        {#each partnerGroup as partner}
          <div class="partner-card" style={partner.style}>
            <img src={partner.image} alt="Partner logo" />
          </div>
        {/each}
      </div>
    {/each}
  </div>
</SectionContainer>

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .partners-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
  }

  .partner-card {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3em;
    width: 7em;
    height: 7em;
    background-color: #fff;
    border-radius: 0.5em;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.1);
    margin: 0.4em;

    @media (max-width: 768px) {
      width: 15vw;
      height: 15vw;
    }
  }

  .partner-card:hover {
    transform: scale(1.1);
    transition: transform 0.1s;
  }
</style>
