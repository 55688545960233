<script>
  import Logo from '../icons/Logo.svelte'
  import handleAnchor, { availableAnchors } from '../utils/handleAnchor.ts'
  import OverlayMenu from './overlayMenu.svelte'

  let showMenu = false
</script>

<OverlayMenu
  {showMenu}
  id="menu"
  closeCallBack={() => {
    showMenu = false
  }}
/>

<header id={availableAnchors.TOP}>
  <Logo />
  <div class="right-buttons">
    <div
      class="sandwich-menu"
      on:keypress={() => (showMenu = true)}
      on:click={() => (showMenu = true)}
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
    <button
      class="hide"
      on:click={() => {
        handleAnchor(document, availableAnchors.SERVICES)
      }}>Nossos serviços</button
    >
    <button
      class="hide"
      on:click={() => {
        handleAnchor(document, availableAnchors.ABOUT)
      }}>Sobre nós</button
    >
    <button class="hide">Fazer orçamento</button>
  </div>
</header>

<style>
  header {
    z-index: 1000;
    height: 5em;
    min-width: 1420px;
    padding-left: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    @media (max-width: 1420px) {
      min-width: 100%;
      width: 100%;
    }
  }

  .right-buttons {
    display: flex;
    align-items: center;
    height: 100%;
  }

  div {
    height: 100%;
  }

  button {
    height: 100%;
    margin-left: 1em;
    background-color: transparent;
    text-align: right;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'popins';
    color: #166d4a;
  }

  button {
    height: 100%;
    margin-left: 1em;
    background-color: transparent;
    text-align: right;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'popins';
    color: #166d4a;
  }

  button:last-child {
    background-color: #166d4a;
    color: #fff;
    padding: 0.5em 1em;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .sandwich-menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 1.2em;
    width: 1.5em;
    cursor: pointer;
    margin: 0 1em;

    @media (max-width: 800px) {
      display: flex;
    }
  }

  .sandwich-menu div {
    width: 100%;
    height: 0.2em;
    background-color: #166d4a;
  }

  .hide {
    @media (max-width: 800px) {
      display: none;
    }
  }
</style>
