export const services = [
    {
        title: 'Pacote Bronze',
        color: '#9B580F',
        price: 'R$ 300,00',
        message: 'Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20Pacote%20Bronze',
        items: ['Diagnóstico de SST', 'Gestão de SST'],
    },
    {
        title: 'Pacote Prata',
        color: '#6C888A',
        price: 'R$ 450,00',
        message: 'Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20Pacote%20Prata',
        items: [
            'Diagnóstico de SST',
            'Gestão de SST',
            'Suporte técnico remoto',
            'Elaboração e revisão de documentos básicos de SST (PGR e PCMSO)',
        ],
    },
    {
        title: 'Pacote Ouro',
        color: '#CDBC1C',
        message: 'Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20Pacote%20Ouro',
        price: 'R$ 600,00',
        items: [
            'Diagnóstico de SST',
            'Gestão de SST',
            'Suporte técnico remoto',
            'Elaboração e revisão de documentos básicos de SST (PGR e PCMSO)',
            'Descontos Especiais em treinamentos de SST (presencial ou EAD)',
        ],
    },
];
