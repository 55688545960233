export const partners = [
    {
        image: 'partners/image1-31.jpeg',
    },
    {
        image: 'partners/image10-46.png',
    },
    {
        image: 'partners/dispel.png',
        style: 'background-color: #f8f8f8',
    },
    {
        image: 'partners/image12-49.png',
    },
    {
        image: 'partners/image13-51.png',
    },
    {
        image: 'partners/image14-53.png',
        style: 'background-color: #06B6C7',
    },
    {
        image: 'partners/image15-54.jpeg',
    },
    {
        image: 'partners/image16-56.jpeg',
    },
    {
        image: 'partners/image18-59.png',
    },
    {
        image: 'partners/image19-61.png',
    },
    {
        image: 'partners/image2-32.png',
    },
    {
        image: 'partners/mase.png',
        style: 'background-color: #f8f8f8',
    },
    {
        image: 'partners/image5-38.jpeg',
    },
    {
        image: 'partners/image9-44.png',
    },
    {
        image: 'partners/image21-64.png',
    },
    {
        image: 'partners/image22-66.jpeg',
    },
    {
        image: 'partners/image23-68.png',
    },
    {
        image: 'partners/ferrero.png',
        style: 'background-color: #f8f8f8',
    },
    {
        image: 'partners/image25-71.png',
    },
    {
        image: 'partners/image26-73.png',
    },
    {
        image: 'partners/image29-78.jpeg',
    },
    {
        image: 'partners/image27-74.png',
    },
    {
        image: 'partners/image8-42.jpeg',
    },
    {
        image: 'partners/image7-40.png',
        style: 'background-color: #FEE103',
    },
    {
        image: 'partners/image28-76.jpeg',
    },
    {
        image: 'partners/image6-39.png',
    },
    {
        image: 'partners/tecnomagnete.png',
        style: 'background-color: #f8f8f8',
    },
    {
        image: 'partners/image30-79.png',
        style: 'background-color: #000',
    },
    {
        image: 'partners/image4-36.png',
    },
    {
        image: 'partners/image3-34.jpeg',
    },
    {
        image: 'partners/image20-62.jpeg',
    },
    {
        image: 'partners/jm.png',
        style: 'background-color: #f8f8f8',
    },
    {
        image: 'partners/image11-48.png',
    },
    {
        image: 'partners/image24-70.jpeg',
    },
    {
        image: 'partners/dia.png',
        style: 'background-color: #d22819',
    },
];
