<script>
  export let height = '2em'
  export let fill = '#AAAAAA'
  export let style = ''
</script>

<div {style}>
  <svg
    {height}
    viewBox="0 0 557 529"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M278.576 0.596924L400.394 124.829L556.189 202.294L475.681 356.539L450.15 528.647L278.576 499.744L107.002 528.647L81.4715 356.539L0.963806 202.294L156.759 124.829L278.576 0.596924Z"
      {fill}
    />
  </svg>
</div>
