<script>
  import handleAnchor, { availableAnchors } from '../utils/handleAnchor'

  export let closeCallBack = () => {}
  export let showMenu = true

  export function accordion(node, show) {
    let initialWidth = 0
    let finalWidth = 20
    node.style.width = show ? finalWidth : initialWidth
    node.style.overflow = 'hidden'
    return {
      update(isOpen) {
        let animation = node.animate(
          [
            {
              width: finalWidth + 'em',
              overflow: 'hidden',
            },
            {
              width: initialWidth + 'em',
              overflow: 'hidden',
            },
          ],
          { duration: 200, fill: 'both', easing: 'ease-in-out' }
        )
        animation.pause()
        if (!isOpen) {
          animation.play()
        } else {
          animation.reverse()
        }
      },
    }
  }
</script>

<div class="container" use:accordion={showMenu}>
  <button
    on:click={() => {
      closeCallBack()
      handleAnchor(document, availableAnchors.SERVICES)
    }}
  >
    Nossos serviços
  </button>
  <button
    on:click={() => {
      closeCallBack()
      handleAnchor(document, availableAnchors.ABOUT)
    }}
  >
    Sobre nós
  </button>
  <button
    on:click={() => {
      closeCallBack()
      handleAnchor(document, availableAnchors.SERVICES)
    }}>Fazer orçamento</button
  >
</div>

{#if showMenu}
  <div
    class="dimmed-back-ground"
    on:keypress={(e) => {
      if (e.key === 'Escape') {
        showMenu = false
        closeCallBack()
      }
    }}
    on:click={() => {
      showMenu = false
      closeCallBack()
    }}
  ></div>
{/if}

<style>
  .dimmed-back-ground {
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .container {
    z-index: 3000;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 20em;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }

  button {
    padding: 1em;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    margin-bottom: 1em;
    margin-right: 1em;
    margin-left: 1em;

    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    color: #166d4a;
    font-family: 'popins';
  }
</style>
