<script>
  export let title
</script>

<h1>
  {title}
</h1>

<style>
  h1 {
    padding: 0 2em;
    font-family: 'popins';
    font-size: 3em;
    font-weight: 700;
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
    color: #777;

    @media (max-width: 800px) {
      font-size: 2em;
    }

    @media (max-width: 600px) {
      font-size: 1.8em;
    }

    @media (max-width: 400px) {
      font-size: 1.4em;
    }
  }
</style>
